import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { RequestCard, RequestType } from '../Types/RequestCard';
import { CopilotRequestStatusBadge } from '../Common/RequestStatusBadge';
import { htmlIf } from '../Utils/HTML';

type Props =
  { requests: RequestCard[]
  }

  function requestDetailsPath(card: RequestCard) {
    switch (card.requestType) {
      case RequestType.PROJECT_REQUEST:
        return `/copilot/requests/p/${card.requestId}`
      case RequestType.HOURLY_CONTRACT:
        return `/copilot/requests/h/${card.requestId}`
    }
  }

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  )
}

const copilotRequestsPath = '/copilot/requests/'

const Requests = (props: Props) => {
  const ViewRequestCard = ({request}: {request: RequestCard}) => {
    return (
      <div className="col-md-6 mb-2">
        <div className="card h-100 rounded-4">
          <a href={requestDetailsPath(request)} className="text-decoration-none">
            <div className="card-body px-3 py-2">
              <div className="row mt-1 align-items-center">
                <div className="col">
                  <div className="h6 mb-1">{request.title}</div>
                  <div className="fs-sm">
                    {(() => {
                      switch (request.requestType) {
                        case RequestType.PROJECT_REQUEST:
                          return `Project request for ${request.customerName}`;
                        case RequestType.HOURLY_CONTRACT:
                          return `Hourly request for ${request.customerName}`;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                  <div className="fs-xs mt-1">
                    Posted on {formatDate(request.createdAt)}
                  </div>
                  <div className="fs-xs mt-1">
                    { request.copilot?.id > 0
                    ? <div className="fw-bold text-primary">Direct request</div>
                    : <div className="fw-bold text-secondary">Open to all CoPilots</div>
                    }
                  </div>
                </div>
                <div className="col-auto">
                  {htmlIf(request.isAgentVirtuoso,
                    <div className="ms-auto badge bg-info text-white">Virtuoso</div>
                  )}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    )
  }

  const ViewEmptyState = () => (
    <div className="my-3 text-center">
      <div className="avatar avatar-md">
        <div className="avatar-title bg-accent rounded text-dark h3">
          <i className="ai-file-text" />
        </div>
      </div>
      <div className="fs-md text-dark mt-2">
        You don't have any open requests right now.
        <br />
        Keep an eye on your email (we'll send you a notification when a new request is available!)
      </div>
      {/* <a href={copilotRequestsPath} className="btn btn-primary mt-2">Claim a request</a> */}
    </div>
  )

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="fw-bold">Open Requests</h4>
        { props.requests.length == 0
        ? <ViewEmptyState />
        : <div className="row mt-3">
            {props.requests.map((request) => (
              <ViewRequestCard
                request={request}
                key={`${request.requestType}-${request.requestId}`}
              />
            ))}
          </div>
        }
      </div>
    </div>
  )
};


export default Requests;
