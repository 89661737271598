import * as React from 'react';
import { useState, useRef } from 'react';
import * as API from '../Utils/API.js';
import { Copilot, CopilotFunFact, copilotMessagesUrl } from '../Types/Copilot';
import { CustomerRating } from '../Types/CustomerRating';
import { Package } from '../Types/Package';
import Perspective from '../Types/Perspective';
import { lastSeenAtDescription } from '../Utils/DateTime'
import { htmlIf, formatMultiParagraphString, maybeHtml } from '../Utils/HTML';
import { SignupButton } from '../Utils/LoginButtons';
import * as Language from '../Utils/Language';
import * as Livestream from '../Types/Livestream';
import { CurrencyCode, ExchangeRate, formatExchangeCurrencyWithoutCents, formatUSDWithoutCents } from '../Utils/Currency';
import { DismissibleModal } from '../Components/Modal';
import EventGrid from '../Livestream/EventGrid';
import PackageCard from './PackageCard';

export type Props =
  { copilot: Copilot
  , reviews: CustomerRating[]
  , copilotPackages: Package[]
  , livestreamEvents: Livestream.Event[]
  , funFacts: CopilotFunFact[]
  , perspective: Perspective
  , hasExistingMessages: boolean
  , preRequestMessagingAllowed: boolean
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  }

const messagesPath = "/messages"
const agentCopilotListPath = "/agent/copilots"

function selectCopilotPath(copilot: Copilot) {
  return `/agent/new-request?copilot_individual_id=${copilot.id}`
}

export const CopilotProfile = (props: Props) => {
  const ViewHeader = () => {
    // Why are these state declarations inside the ViewHeader function? Because, for some reason, React complains
    // that we're rendering more hooks than expected when they're one level up, so we keep them here.
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [message, setMessage] = useState('');

    function sendMessage() {
      setIsSendingMessage(true);
      const body = { copilotIndividualId: props.copilot.id, message: message }
      API.post("messages_send_copilot_cold_message_path", body).then(function (result) {
        if (result['error']) {
        } else {
          window.location.href = messagesPath
        }
        setShowMessageModal(false);
        setIsSendingMessage(false);
      })
    }

    const ViewMessageModal = () => {
      return (
        <DismissibleModal
          title={<h4>Send a message</h4>}
          body={
            <div>
              <div className="">{`Enter the message you wish to send to ${props.copilot.preferredName}:`}</div>
              <textarea className="form-control mt-2"
                placeholder="Enter a message…"
                rows={4}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </div>
          }
          footer={
            <>
              <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                onClick={() => setShowMessageModal(false)}
                disabled={isSendingMessage}
              >
                Cancel
              </button>
              <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                onClick={() => sendMessage()}
                disabled={isSendingMessage}
              >
                Send
              </button>
            </>
          }
          onDismiss={() => setShowMessageModal(false)}
        />
      )
    }

    return (
      <div className="card rounded-4">
        {htmlIf(showMessageModal, ViewMessageModal())}
        <div className="card-img-top bg-primary rounded-top" style={{paddingTop: '7rem'}}/>
        <div className="d-none d-md-block">
          <div className="row align-items-end">
            <div className="col mt-n1">
              <div className="avatar avatar-xxl ms-2" style={{marginTop: '-5rem'}}>
                <img className="avatar-img rounded-circle border-white border border-4" src={props.copilot.imageUrl}/>
              </div>
            </div>
            {htmlIf(props.perspective === Perspective.Anonymous,
              <div className="col-auto me-3">
                <SignupButton className="btn btn-outline-primary" buttonText="Work with this CoPilot" />
              </div>
            )}
            {htmlIf(props.perspective === Perspective.Agent,
              <div className="col-auto me-3">
                { props.hasExistingMessages && false
                ? <a className="btn btn-outline-secondary" href={copilotMessagesUrl(props.copilot)}>
                    Messages
                  </a>
                : props.preRequestMessagingAllowed
                  ? <button className="btn btn-outline-primary" onClick={() => setShowMessageModal(true)}>
                      Message
                    </button>
                  : null
                }
                <a className="btn btn-primary ms-2" href={selectCopilotPath(props.copilot)}>
                  Select CoPilot
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="d-md-none text-center">
          {/* Note: Manually overriding the width and height here — our avatar-xxl class forces a smaller avatar-xxl on narrow screens. */}
          <div className="avatar avatar-xxl" style={{marginTop: '-5rem', width: '8rem', height: '8rem'}}>
            <img className="avatar-img rounded-circle border-white border border-4" src={props.copilot.imageUrl}/>
          </div>
          {htmlIf(props.perspective === Perspective.Anonymous,
            <div className="mt-2">
              <SignupButton className="btn btn-sm px-3 btn-outline-primary" buttonText="Work with this CoPilot" />
            </div>
          )}
          {htmlIf(props.perspective === Perspective.Agent,
            <div className="mt-2">
              <a className="btn btn-sm px-3 btn-outline-secondary" href={copilotMessagesUrl(props.copilot)}>
                Messages
              </a>
              <a className="btn btn-sm px-3 btn-outline-primary ms-2" href={selectCopilotPath(props.copilot)}>
                Select CoPilot
              </a>
            </div>
          )}
        </div>
        <div className="card-body pt-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="fs-xl fw-bold text-dark">{props.copilot.preferredName}</div>
            </div>
            <div className="col-auto">
              {htmlIf((props.copilot.averageRating !== null && props.copilot.numRatings > 1 && props.perspective != Perspective.Anonymous),
                <div className="text-dark d-flex align-items-center">
                  <i className="ai-star me-1"/>
                  {props.copilot.averageRating?.toFixed(1)}
                  <span className="text-secondary ms-1">({props.copilot.numRatings} reviews)</span>
                </div>
              )}
            </div>
          </div>
          {htmlIf(props.copilot.location !== null,
            <div className="d-flex align-items-center mt-1">
              <i className="ai-map-pin me-1" />
              {props.copilot.location}
            </div>
          )}
          {htmlIf(props.copilot.pronouns !== null,
            <div className="d-flex align-items-center mt-1">
              <i className="ai-user me-1" />
              {props.copilot.pronouns}
            </div>
          )}
          {htmlIf((props.copilot.hourlyRateCents !== null && props.copilot.hourlyRateCents > 0),
            <div className="d-flex align-items-center mt-1">
              <i className="ai-card me-1" />
              {formatExchangeCurrencyWithoutCents(props.displayCurrency, props.copilot.hourlyRateCents, props.exchangeRates)}/hour
            </div>
          )}
          {htmlIf(props.copilot.languageCodes.length > 0,
            <div className="d-flex align-items-center mt-1">
              <i className="ai-globe me-1" />
              <div>
                {props.copilot.languageCodes.map((code, index) => (
                  <React.Fragment key={index}>
                    {Language.name(code)}
                    {index < props.copilot.languageCodes.length - 1 ? " | " : ""}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          {htmlIf(props.copilot.workStyles.length > 0,
            <div className="d-flex align-items-center mt-1">
              <i className="ai-briefcase me-1" />
              <div>
                { props.copilot.workStyles.length === 0
                ? 'This text should never show up, we just need a condition for an empty array! Grrr'
                : props.copilot.workStyles.length === 1
                ? `Open to ${props.copilot.workStyles[0].name.toLowerCase()} work`
                : props.copilot.workStyles.length === 2
                ? `Open to ${props.copilot.workStyles[0].name.toLowerCase()} and ${props.copilot.workStyles[1].name.toLowerCase()} work`
                : `Open to ${props.copilot.workStyles.slice(0, -1).map((item) => item.name.toLowerCase()).join(', ')}, and ${props.copilot.workStyles[props.copilot.workStyles.length - 1].name.toLowerCase()} work`
                }
              </div>
            </div>
          )}
          {htmlIf(props.copilot.tags.length > 0,
            <div className="d-flex align-items-center flex-wrap">
              <i className="ai-tag me-1 mt-1" />
              {props.copilot.tags.map((tag) => (
                <div key={`Tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.name}</div>
              ))}
            </div>
          )}
          {htmlIf(props.copilot.lastSeenAt,
            <div className="d-flex align-items-center mt-1">
              <i className="ai-time me-1" />
              <div className="fs-md">{lastSeenAtDescription(props.copilot.lastSeenAt)}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const ViewBio = () => (
    <div className="card mt-3 rounded-4">
      <div className="card-body py-3">
        <div className="fs-xl fw-bold text-dark">About</div>
        <div className="fs-md mt-2">{formatMultiParagraphString(props.copilot.bio)}</div>
      </div>
    </div>
  )

  function formatEarnings(amountCents: number):string {
    let dollars = amountCents / 100
    if (dollars < 100) {
      return "< $100"
    } else if (dollars < 1000) {
      let rounded = Math.floor(dollars / 100) * 100;
      return `$${rounded}+`
    } else if (dollars > 1000) {
      let rounded = Math.floor(dollars / 1000);
      return `$${rounded}K+`
    } else {
      return "--"
    }
  }

  const ViewCompletedJobs = () => (
    <div className="card mt-3 rounded-4">
      <div className="card-body py-3">
        <div className="row align-items-center">
          <div className="col-md-4 d-flex align-items-center">
            <div className="avatar avatar-md">
              <div className="avatar-title bg-accent rounded-1 text-primary h3">
                <i className="ai-tool" />
              </div>
            </div>
            <div className="ms-2">
              <div className="fs-lg fw-bold text-dark">{props.copilot.jobsInProgress}</div>
              {props.copilot.jobsInProgress === 1 ? 'Job' : 'Jobs'} in Progress
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center mt-2 mt-md-0">
            <div className="avatar avatar-md">
              <div className="avatar-title bg-accent rounded-1 text-primary h3">
                <i className="ai-circle-check" />
              </div>
            </div>
            <div className="ms-2">
              <div className="fs-lg fw-bold text-dark">{props.copilot.jobsCompleted}</div>
              {props.copilot.jobsCompleted === 1 ? 'Job' : 'Jobs'} Completed
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center mt-2 mt-md-0">
            <div className="avatar avatar-md">
              <div className="avatar-title bg-accent rounded-1 text-primary h3">
                <i className="ai-dollar" />
              </div>
            </div>
            <div className="ms-2">
              <div className="fs-lg fw-bold text-dark">{formatEarnings(props.copilot.lifetimeEarningsCents)}</div>
              Total Earnings
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const ViewCopilotPackages = () => (
    <div className="card mt-3 rounded-4">
      <div className="card-body py-3">
        <div className="fs-xl fw-bold text-dark">Packages</div>
        <div className="row">
          {props.copilotPackages.map((pkg) => (
            <div className="col-md-6 col-lg-4 mt-3">
              {PackageCard(pkg)}
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  const ViewFunFacts = () => (
    <div className="card mt-3 rounded-4">
      <div className="card-body py-3">
        <div className="fs-xl fw-bold text-dark mb-3">Fun Facts</div>
        {props.funFacts.map((funFact) => (
          <div key={funFact.emoji} className="row g-0 no-gutters align-items-center mb-2">
            <div className="col-auto justify-content-center">
              <div className="avatar avatar-md me-2">
                <div className="avatar-title bg-accent rounded-1 lead">{funFact.emoji}</div>
              </div>
            </div>
            <div className="col">
              <div className="fs-sm">{funFact.question}</div>
              <div className="fs-md text-dark">{funFact.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const ViewReviews = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const visibleReviews = isExpanded ? props.reviews : props.reviews.slice(0, 3);

    const ViewSingleReview = (review: CustomerRating, showBorder: boolean) => (
      <div key={review.id} className={`pb-3 mb-2 ${showBorder ? 'border-bottom' : ''}`}>
        <div className="fs-md mb-1 fw-bold text-dark">{review.ratedByName}</div>
        <div className="d-flex align-items-center mb-1">
          {Array.from({ length: review.rating }, (_, index) => (
            <i key={index} className="ai-star-filled text-primary fs-lg" />
          ))}
          <div className="ms-1 fs-lg fw-bold text-primary">{review.rating}</div>
          <div className="ms-1 fs-sm">| {review.createdTimeAgo}</div>
        </div>
        <div className="fs-md">{review.ratingComment}</div>
      </div>
    )

    return (
      <div className="card mt-3 rounded-4">
        <div className="card-body py-3">
          <div className="fs-xl fw-bold text-dark mb-2">Reviews</div>
          {visibleReviews.map((review, index) => (
            ViewSingleReview(review, (index < visibleReviews.length - 1))
          ))}
          {props.reviews.length > 3 && !isExpanded && (
            <button className="btn btn-link fw-bold ps-0 pt-0 mt-n6" onClick={() => setIsExpanded(true)}>Show More</button>
          )}
        </div>
      </div>
    );
  }

  const ViewAnonymousSignUpCta = () => (
    <div className="card mt-3 rounded-4 bg-accent">
      <div className="card-body py-3">
        <h4 className="fw-bold">Want to work with {props.copilot.preferredName}?</h4>
        <div className="fs-md text-dark">Join Lucia and get started with a free trial!</div>
        <div className="mt-2">
          <SignupButton className="btn btn-primary" buttonText="Get started" />
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {htmlIf(props.perspective != Perspective.Anonymous && props.perspective != Perspective.ViewOnly,
        <div className="col-auto d-flex align-items-center mb-2">
          <a href={agentCopilotListPath} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-user-group me-1"/>
            All CoPilots
          </a>
      </div>
      )}
      <ViewHeader />
      {htmlIf(props.perspective != Perspective.Anonymous,
        <ViewCompletedJobs />
      )}
      {htmlIf(props.perspective === Perspective.Anonymous,
        <ViewAnonymousSignUpCta />
      )}
      <ViewBio />
      {htmlIf(props.copilotPackages.length > 0,
        <ViewCopilotPackages />
      )}
      {htmlIf(props.reviews.length > 0,
        <ViewReviews />
      )}
      {htmlIf(props.livestreamEvents.length > 0,
        <div className="card mt-3 rounded-4">
          <div className="card-body py-3">
            <div className="fs-xl fw-bold text-dark mb-2">Livestreams by {props.copilot.preferredName}</div>
            <EventGrid events={props.livestreamEvents.slice(0,3)} perspective={props.perspective} targetBlank={true}/>
          </div>
        </div>
      )}
      {htmlIf(props.funFacts.length > 0,
        <ViewFunFacts />
      )}
    </div>
  )
}

export default CopilotProfile;
