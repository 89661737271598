import * as React from 'react';
import { useState, useEffect } from 'react';
import { RequestCard, RequestType } from '../Types/RequestCard';
import { CopilotRequestStatusBadge } from '../Common/RequestStatusBadge';
import { htmlIf } from '../Utils/HTML';

type Props =
  { requests: RequestCard[]
  }

function requestDetailsPath(card: RequestCard) {
  switch (card.requestType) {
    case RequestType.PROJECT_REQUEST:
      return `/copilot/requests/p/${card.requestId}`
    case RequestType.HOURLY_CONTRACT:
      return `/copilot/requests/h/${card.requestId}`
  }
}

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  )
}

const copilotOpenRequestsPath = '/copilot/requests/open-requests'

const Requests = (props: Props) => {
  enum SortOptions
    { RecentlyUpdated
    , NameAsc
    , NameDesc
    , CreatedAtAsc
    , CreatedAtDesc
    , Status
    }

  const allSortOptions = [
    SortOptions.RecentlyUpdated,
    SortOptions.Status,
    SortOptions.NameAsc,
    SortOptions.NameDesc,
    SortOptions.CreatedAtAsc,
    SortOptions.CreatedAtDesc
  ]

  function sortOptionDisplayName(sortOption: SortOptions): string {
    switch (sortOption) {
      case SortOptions.RecentlyUpdated:
        return "Recently Updated"
      case SortOptions.Status:
          return "Status (Open/In Progress/Completed)"
      case SortOptions.NameAsc:
        return "Name (A–Z)"
      case SortOptions.NameDesc:
        return "Name (Z–A)"
      case SortOptions.CreatedAtAsc:
        return "Posting Date (Oldest to Newest)"
      case SortOptions.CreatedAtDesc:
        return "Posting Date (Newest to Oldest"
    }
  }
  const sortRequests = (requests: RequestCard[]): RequestCard[] => {
    switch (sortOption) {
      case SortOptions.RecentlyUpdated:
        return requests.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      case SortOptions.NameAsc:
        return requests.sort((a, b) => a.title.localeCompare(b.title));
      case SortOptions.NameDesc:
        return requests.sort((a, b) => b.title.localeCompare(a.title));
      case SortOptions.CreatedAtAsc:
        return requests.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      case SortOptions.CreatedAtDesc:
        return requests.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      case SortOptions.Status:
        return requests.sort((a, b) => {
          const order = {
            'submitted': 1,
            'declined-pending': 2,
            'claimed': 3,
            'completed': 4,
            'withdrawn': 5,
            'refunded': 6
          };

          return order[a.status] - order[b.status];
        });
      default:
        return requests;
    }
  }

  const [sortOption, setSortOption] = useState(SortOptions.RecentlyUpdated);
  const [allRequests, setAllRequests] = useState(props.requests);
  const [searchText, setSearchText] = useState('');
  const [filteredRequests, setFilteredRequests] = useState(props.requests);

  // This hook serves to re-sort and re-filter the list of requests any time one of the following occurs:
  // 1) The underlying list of requests changes (allRequests)
  // 2) The sort option is changed
  // 3) The search text is changed
  useEffect(() => {
    const sortedRequests = sortRequests(allRequests);

    const filtered = sortedRequests.filter((request) => {
      const searchFields = [
        request.title,
        request.description,
        request.customerName,
        CopilotRequestStatusBadge({status: request.status, isDirectRequest: (request.copilot?.id > 0)}).props.children
      ];
      return (
        searchFields.some((field) =>
          field.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredRequests(filtered);
  }, [allRequests, sortOption, searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  const onSelectSortOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = parseInt(event.target.value) as SortOptions;
    setSortOption(selectedSortOption);
  }

  const ViewRequestCard = ({request}: {request: RequestCard}) => {
    return (
      <div className="col-md-6 mb-2">
        <div className="card h-100 rounded-4">
          <a href={requestDetailsPath(request)} className="text-decoration-none">
            <div className="card-body p-3">
              <div className="h6 mb-1">{request.title}</div>
              <div className="d-flex align-items-center">
                <div className="fs-sm text-gray-900">{request.customerName}</div>
                {htmlIf(request.isAgentVirtuoso,
                  <div className="ms-1 fs-xs badge small bg-info text-white">Virtuoso</div>
                )}
              </div>
              <div className="fs-xs mt-1">
                Posted on {formatDate(request.createdAt)}
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <CopilotRequestStatusBadge status={request.status} isDirectRequest={request.copilot?.id > 0}/>
                <div className="fs-md fw-semibold text-gray-900">{request.copilotRateDescription}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    )
  }

  const ViewEmptyState = () => (
    <div className="my-3 text-center">
      <div className="avatar avatar-md">
        <div className="avatar-title bg-accent rounded text-dark h3">
          <i className="ai-file-text" />
        </div>
      </div>
      <div className="fs-lg text-dark mt-2">You don't have any requests yet — claim one now to get started!</div>
      <a href={copilotOpenRequestsPath} className="btn btn-primary mt-2">Claim a request</a>
    </div>
  )

  return (
    <div className="">
      <div className="">
        { allRequests.length == 0
        ? <ViewEmptyState />
        :
          <>
            <div className="row mt-3 align-items-center">
              <div className="col-md-8">
                <div className="input-group align-items-center p-0 bg-white">
                  <div className="input-group-prepend ms-2">
                    <i className="ai-search"></i>
                  </div>
                  <input
                    className="form-control"
                    type="search"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    placeholder="Search by title, description, or client…"
                  />
                </div>
              </div>
              {/* <div className="col-md-3 mt-2 mt-md-0 text-end"> -- FOR FILTERS
                <select className="form-select bg-white" onCha
              </div> */}
              <div className="col-md-4 mt-2 mt-md-0 text-end">
                <select className="form-select bg-white" onChange={onSelectSortOption} value={sortOption}>
                  {allSortOptions.map((option: SortOptions) => (
                    <option key={option} value={option}>
                      {sortOptionDisplayName(option)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              {filteredRequests.map((request) => (
                <ViewRequestCard
                  request={request}
                  key={`${request.requestType}-${request.requestId}`}
                />
              ))}
            </div>
          </>
        }
      </div>
    </div>
  )
};


export default Requests;
