import * as React from 'react';
import { useRemarkSync } from 'react-remark';

/**
 * Renders the provided HTML element if the `shouldRender` argument is truthy, or returns `null` otherwise.
 *
 * @param shouldRender Any value, for which, if truthy, the element should be rendered.
 * @param html The HTML element to render if `shouldRender` is true.
 * @returns The rendered HTML element or `null`.
 */
export function htmlIf<T>(value: T | null | undefined, html: JSX.Element) {
  return value ? html : null;
}

/**
 * Renders the result of the provided render function with the `maybeValue` argument as its input,
 * if `maybeValue` is not `null` or `undefined`. Otherwise, returns `null`.
 *
 * @param maybeValue The value to render, if not `null` or `undefined`.
 * @param render The render function that should be called with the `maybeValue` argument as input.
 * @returns The rendered output of the `render` function, or `null`.
 */
export function maybeHtml<T>(maybeValue: T | null | undefined, render: (value: T) => React.ReactElement): React.ReactElement {
  return maybeValue != null ? render(maybeValue) : null;
}

/**
 * Formats a multi-paragraph string into a React element with each paragraph as plain text separated by line breaks.
 *
 * @param str - The multi-paragraph string to format.
 * @returns A React element representing the formatted string with each paragraph as plain text separated by line breaks.
 */
export function formatMultiParagraphString(str: string): React.ReactElement {
  if (str === null) {
    return null
  }

  const paragraphs = str.split('\n');

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          {index !== paragraphs.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );
}

/**
 * Formats a string with Markdown into HTML. Note that this also converts link-literals into auto-links and makes
 * line breaks more closely resemble input text as opposed to Markdown's funky way of handling line breaks.
 * @param str The string (with Markdown formatting) to be rendered to HTML.
 * @returns A React element representing the formatted string converted to Markdown display.
 */
export function formatMarkdown(str: string): React.ReactElement {
  const autolinked = convertToMarkdownWithLinks(str)
  const lineBreaked = formatLineBreaksForMarkdown(autolinked)
  return (
    useRemarkSync(lineBreaked, {
      rehypeReactOptions: { components: {
        p: (props) => <div {...props} />,
        a: (props) => <a target="_blank" {...props} />
      }}
    })
  )
}

// Ideally, we'd use a package like remark-gfm to handle auto-linking of links in Markdown, but it doesn't seem
// to work for server-side rendering. Accordingly, this function gets our links into the Markdown link format
// (so they're automatically hyperlinked). Courtesy of ChatGPT!
function convertToMarkdownWithLinks(str: string): string {
  // For Regex editing, use ChatGPT!
  // const regex = /((?:https?:\/\/)?(?:www\.)?(?:[^\s]+\.[^\s]+)|[^\s@]+@[^\s@]+\.[^\s@]+)(\.?)\b/g;
  // Modified from the above to the below on 10/3/23 to prevent capturing of "e.g." and "i.e." (and other cases
  // where a period is immediately followed by a single letter or 1+ numerical digits).
  // const regex = /((?:https?:\/\/)?(?:www\.)?(?:[^\s]+\.(?![A-Za-z]\b|\d+\b)[^\s]+)|[^\s@]+@[^\s@]+\.[^\s@]+)(\.?)\b/g;
  // Modified from the above to the below on 11/14/23 to prevent capturing of special characters at the start of URLs —
  // (www.google.com) was linking with the () included, and our new regex removes the () from the link.
  const regex = /([A-Za-z](?:https?:\/\/)?(?:www\.)?(?:[^\s]+\.(?![A-Za-z]\b|\d+\b)[^\s]+)|[^\s@]+@[^\s@]+\.[^\s@]+)(\.?)\b/g;

  const markdownTemplate = (match: string, link: string, period: string) => {
    if (link.startsWith('http://') || link.startsWith('https://')) {
      return `[${link}](${link})${period}`;
    } else if (link.includes('@')) {
      const email = link.replace(/\.$/, ''); // Remove trailing period from email address
      return `[${email}](mailto:${email})${period}`; // Include trailing period outside the email link
    } else {
      return `[${link}](https://${link})${period}`;
    }
  };
  return str.replace(regex, markdownTemplate);
}

// Markdown handles line breaks differently than we'd like (i.e. single line breaks don't work, multi-line breaks only
// appear as single line breaks, different line break rules for lists/headers). Accordingly, we do some conversion here
// to ensure that the text we pass to our Markdown formatter will end up in the proper format with regard to line breaks.
// This code was workshopped with ChatGPT and gets us 90% of the way there — there's still an issue that new lines after the end
// of lists won't trigger as new lines unless there's an empty line between the end of the list and the new line, but it's a
// small enough issue that we're going to leave it as-is for now.
function formatLineBreaksForMarkdown(str: string): string {
  const lines = str.trimEnd().split(/\r\n|\r|\n/);

  const formattedLines = lines.map((line, index) => {
    const isExcludedLine = /^(\*|-|\d+\.)|#*\s/.test(line);
    const isLastLine = index === lines.length - 1;
    const isNextLineEmpty = !lines[index + 1]?.trim();
    const isNextLineExcluded = /^(\*|-|\d+\.\s|#\s*)/.test(lines[index + 1] || '');
    const isPreviousLineExcluded = /^(\*|-|\d+\.\s|#\s*)/.test(lines[index - 1] || '');
    const formattedLine =
      isExcludedLine || (isLastLine && isNextLineEmpty) || isNextLineExcluded || isPreviousLineExcluded ? line : line + "\\";

    return formattedLine;
  });

  return formattedLines.join("\n");
}

// Earlier, simpler version of the formatLineBreaksForMarkdown that doesn't take into account lists/headings.
// export function formatLineBreaks(str: string): string {
//   return str.replace(/\r\n|\r|\n/g, "\\\n");
// }

/**
 * A Typescript representation of the Rails `parameterize` function, frequently used in URLs.
 * Converts a string to a parameterized version.
 * Replaces non-alphanumeric characters with a separator,
 * removes consecutive separators, and trims leading/trailing separators.
 *
 * @param {string} string - The input string to be parameterized.
 * @param {string} [separator='-'] - (Optional) The separator to be used; defaults to `-`
 * @returns {string} - The parameterized string.
 */
export function parameterize(string: string, separator = '-'): string {
  const trimmedString = string.trim().toLowerCase();
  const sanitizedString = trimmedString.replace(/[^a-z0-9\-]+/gi, separator);
  const normalizedString = sanitizedString.replace(/-{2,}/g, separator);
  const finalString = normalizedString.replace(/^(\-)|(\-)$/g, '');
  return finalString;
};
