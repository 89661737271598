import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

type CopilotApplication =
  { id: number
  , email: string
  , fullName: string
  , createdAt: string
  , updatedAt: string
  , status: CopilotApplicationStatus
  }

enum CopilotApplicationStatus
  { PENDING = 'Pending'
  , APPROVED = 'Approved'
  , REJECTED = 'Rejected'
  }

type Props =
  { applications: CopilotApplication[]
  }

function applicationDetailsPath(application) {
  return `/admin/copilot/applications/${application.id}`
}

const formatTime = (timeString: string): string => {
  const time = new Date(timeString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'numeric',
    year: '2-digit',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',

  };

  return time.toLocaleString('en-US', options);
};

const CopilotApplicationList = (props: Props) => {
  enum SortOptions
    { NameAsc
    , NameDesc
    , Status
    , SubmissionAsc
    , SubmissionDesc
    }

  const allSortOptions = [
    SortOptions.NameAsc,
    SortOptions.NameDesc,
    SortOptions.Status,
    SortOptions.SubmissionAsc,
    SortOptions.SubmissionDesc
  ]

  function sortOptionDisplayName(sortOption: SortOptions): string {
    switch (sortOption) {
      case SortOptions.NameAsc:
        return "Name (A–Z)"
      case SortOptions.NameDesc:
        return "Name (Z–A)"
      case SortOptions.Status:
        return "Status"
      case SortOptions.SubmissionAsc:
        return "Submission Time (Oldest to Newest)"
      case SortOptions.SubmissionDesc:
        return "Submission Time (Newest to Oldest)"
    }
  }
  const sortCopilots = (applications: CopilotApplication[]): CopilotApplication[] => {
    switch (sortOption) {
      case SortOptions.NameAsc:
        return applications.sort((a, b) => a.fullName.localeCompare(b.fullName));
      case SortOptions.NameDesc:
        return applications.sort((a, b) => b.fullName.localeCompare(a.fullName));
      case SortOptions.Status:
        return applications.sort((a, b) => {
          const order = {
            'Pending': 1,
            'Approved': 2,
            'Rejected': 3
          };

          return order[a.status] - order[b.status];
        });
      case SortOptions.SubmissionAsc:
        return applications.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
      case SortOptions.SubmissionDesc:
        return applications.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      default:
        return applications;
    }
  }

  const [sortOption, setSortOption] = useState(SortOptions.SubmissionDesc);
  const [searchText, setSearchText] = useState('');
  const [filteredApplications, setFilteredApplications] = useState(props.applications);

  // This hook serves to re-sort and re-filter the list of applications any time one of the following occurs:
  // 2) The sort option is changed
  // 3) The search text is changed
  useEffect(() => {
    const sortedCopilots = sortCopilots(props.applications);

    const filtered = sortedCopilots.filter((application) => {
      const searchFields = [
        application.fullName,
        application.email,
        `${application.id}`
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredApplications(filtered);
  }, [sortOption, searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  const onSelectSortOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = parseInt(event.target.value) as SortOptions;
    setSortOption(selectedSortOption);
  }

  return (
    <>
      <h3>All CoPilot Applications</h3>
      <div className="row mt-3 align-items-center">
        <div className="col-md-8">
          <div className="input-group align-items-center p-0 bg-white">
            <div className="input-group-prepend ms-2">
              <i className="ai-search"></i>
            </div>
            <input
              className="form-control"
              type="search"
              value={searchText}
              onChange={handleSearchInputChange}
              placeholder="Search by ID, name, or email…"
            />
          </div>
        </div>
        <div className="col-md-4 mt-2 mt-md-0 text-end">
          <select className="form-select bg-white" onChange={onSelectSortOption} value={sortOption}>
            {allSortOptions.map((option: SortOptions) => (
              <option key={option} value={option}>
                {sortOptionDisplayName(option)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-3 card rounded-1">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Submission Time</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {filteredApplications.length === 0 ? (
                  <tr>
                    <td className="py-6 text-center" colSpan={5}>
                      <h3 className="text-secondary mt-3">No applications were found</h3>
                    </td>
                  </tr>
                ) : (
                  filteredApplications.map((a) => (
                    <tr key={a.id}>
                      <td className="text-nowrap py-1">{a.id}</td>
                      <td className="text-nowrap py-1">{a.fullName}</td>
                      <td className="text-nowrap py-1">{a.email}</td>
                      <td className="text-nowrap py-1">{formatTime(a.updatedAt)}</td>
                      <td className="text-nowrap py-1">{a.status}</td>
                      <td className="align-middle py-1">
                        {a.status === CopilotApplicationStatus.PENDING ? (
                          <a className="btn btn-primary btn-sm m-0 px-2 py-1" href={applicationDetailsPath(a)}>
                            View application
                          </a>
                        ) : a.status === CopilotApplicationStatus.APPROVED ? (
                          <a className="btn btn-outline-success btn-sm m-0 px-2 py-1" href={applicationDetailsPath(a)}>
                            View application
                          </a>
                        ) : (
                          <a className="btn btn-outline-danger btn-sm m-0 px-2 py-1" href={applicationDetailsPath(a)}>
                            View application
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default CopilotApplicationList;
